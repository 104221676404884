import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Button from "./button"

class Inhaltsverzeichnis extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {  };

  }
  
  handleClick = () => {
    
  }
  render() {
    return (
    <div className="content-list" style={{display: "flex", flexDirection: "column", position: "fixed", top: 125, padding: 10, border: "1px solid var(--color-primary)", borderRadius: 5}}>
        <div className='' style={{fontWeight: 500, fontSize: 18, padding: 5, paddingLeft: 0}}>
            Inhaltsverzeichnis
        </div>
        {this.props.content.map((a, i) => {
            return (
                <a key={i} href="#" className={"item " + (a.type === "subtitle" ? "subtitle" : "")} style={{padding: 5}} onClick={(e) => {
                    e.preventDefault()
                    let element = document.querySelector('#' + a.id);
                    const rect = element.getBoundingClientRect();
                    let pos = rect.top + window.scrollY
                    window.scrollTo({
                        top: pos,
                        behavior: 'smooth'
                    })
                }}>{a.text}</a>
            )
        })}
   </div>
   )
  }
 
}


export default Inhaltsverzeichnis
