import * as React from "react"
import PropTypes, { nominalTypeHack } from "prop-types"
import "./layout.css"


const Video = ({ text, title, description, srcUrl, duration, channelName, channelLink}) => (
    <div className="video" style={{minHeight: 220, display: "flex", width: "90%", margin: "25px auto", padding: 25, justifyContent: "center", boxShadow: "0px 0px 5px 1px rgb(0 0 0 / 7%)"}}>
        <div style={{marginRight: 15, display: "flex", flexDirection: "column"}}>
            <h6 style={{fontSize: "18px", marginTop: 0, marginBottom: 5}}>{title}</h6>
            <p className="small">{description}</p>
            <div className="very-small row" style={{marginTop: "auto", display: "flex"}}>
                <p className="very-small">Von: &nbsp;</p>
                <a className="very-small" href={channelLink}>{channelName}</a>
                <p className="very-small" style={{marginLeft: 15}}>{"Dauer: " + duration}</p>
            </div>
        </div>
        <div style={{minWidth: 250}}>
            <div className='youtube-container' style={{ display: "flex", height: "auto", margin: "auto"}}>
                <iframe
                    src={srcUrl}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                />
            </div>
        </div>
    </div>
)



export default Video
