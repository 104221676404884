import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
import fastFurious from '../images/2013_-_Fast_%26_Furious.webp'
import Inhaltsverzeichnis from "../components/inhaltsverzeichnis.jsx"
import go from '../images/tracks/go.webp'
import first from '../images/tracks/first.webp'
import evolution from '../images/tracks/evolution.webp'
import d124 from '../images/tracks/d124.webp'

const Ratgeber = () => (
  <Layout>
    <Seo title="Kaufberatung für Carrera-Rennbahnen. Carrera Bahn: Digital 124 - Digital 132 - Carrera Go" description={"Unsicher welche Carrera Bahn für dich richtig ist? Villeicht können wir dir helfen."}/>
      
      <div className='content'>
      <div className='row'>
        <div className=''>
          <h1 style={{marginBottom: 15}}>Kaufberatung für Carrera-Rennbahnen.</h1>
          <p>Slotcars unterscheiden sich von Spielzeugautos, die rollen oder motorisiert sind und keine Schiene haben. Diese erfordern keine Kontrolle über das Auto durch den Benutzer. Im Gegensatz zu diesen Spielzeugen erfordern Slotcars ein gewisses fahrerisches Können, das zu einem großen Teil auf Instinkt und ein Gefühl für das Auto und die Strecke zurückzuführen ist.

<br />Ein <b>Slotcar</b> ist ein maßstabsgetreues Miniaturauto, das von einem <b>kleinen Elektromotor angetrieben</b>  wird.  Die Autos werden auf einer Rennstrecke gefahren, die für jede Fahrzeugspur eine Rille hat, und das Slotcar hat einen kleinen Stift oder ein Blatt, das von unten in die Rille hineinragt. Die Kontaktbürsten nehmen den Strom von den Schienen zum Motor auf und treiben das Auto an. Der Führungsstift hält das Auto in der Schiene. Gesteuert wird das Fahrzeug mit einem Handregler, der den Spannungswert filtert.
</p>
          <h2 id="digitalanalog" className="border">Digital oder Analog?</h2>
          <p>Die Geschwindigkeit der Fahrzeuge kann bei den digtalen Bahnen eingestellet werden und es gibt 10 Stufen. Im gegensatz zu den Analogen Bahnen können bei den Digitalen Bahnen mehrere Autos auf der gleichen spur rasen und taktisches fahren ist durch spurwechsel und Überholmanöver möglich. Sie können die Bahn so weit ausbauen, wie es Ihr Keller zulässt.</p>
          <h2 id="analog" className="border" >Analoge Bahnen</h2>

          <div className='row'>
            <div className=''>
              <h3 id="first">Carrera First</h3>
              <p><b>Für Vorschulkinder</b> gibt es die Carrera First Bahn. Es ist für Kinder <b>ab drei Jahren</b> geeignet. Da die Steuerung einfach zu bedienen ist, können kleine Kinder mit Freude spielen, ohne frustriert zu werden. Die Autos für Carrera First sind oft aus dem Film <b>Cars oder Mario Kart</b>. Die Handregler sind speziell für Kinderhände gemacht. Das macht das spielen für die Kinder zwar bequemer aber kann dazu führen das sie wenn das Kinder älter wird eine neue Bahn kaufen müssen.</p>
            </div>
            <div className='' >
              <img src={first} className="margin" alt="carrera first" style={{width: 240, minWidth: 240, marginLeft: 15, objectFit: "contain"}}/>
            </div>
          </div>
          <Video title="Carrera First Video" description={"Wenn sie einen Eindruck von der Bahn bekommen wollen oder hilfe bei dem Aufbau brauchen, hilft ihnen vielleicht dieses Video."} srcUrl={"https://www.youtube.com/embed/JW59IkToIG8"} duration="2 Minuten 34 Sekunden" channelLink="https://www.youtube.com/c/Carrera_official/videos" channelName="Carrera"  />
          <div className='row'>
            <div className=''>
              <h3 id="evolution">Carrera Evolution</h3>
              <p>Das Set, mit dem die meisten Menschen vertraut sind, ist Carrera Evolution. Es wird für Kinder ab acht Jahren empfohlen. Die Strecke ist im Maßstab 1:24 und die Fahrzeuge im Maßstab <b>1:32</b> (ungefähr 15cm lang) gehalten. Im Grundset sind zwei Autos enthalten. Das analoge Set kann <b>nachträglich</b> in ein <b>digitales umgewandelt</b>   werden. </p>
            </div>
            <div className='' >
              <img src={evolution} className="margin" alt="Carrera Evolution" style={{width: 240, minWidth: 240, marginLeft: 15, objectFit: "contain"}}/>
            </div>
          </div>
          <div className='row'>
            <div className=''>
              <h3 id="go">Carrera Go und Go Plus</h3>
              <p>Carrera Go ist für Kinder ab einem Alter von <b>sechs Jahren</b> zu empfehlen. Der Maßstab der Autos und Bahnen ist <b>1:43</b>, also in etwa 12cm lang. <br />Im Vergleich zum Vorschulset ist die manuelle Steuerung mit Turbo-Option ausgefeilter. <br /><br />Für Carrera Go gibt es Kindliche Fahrzeuge von Nintendo Mario Kart oder Cars aber auch eine riesige Auswahl modelle echter Autos. Außerdem gibt es coole Streckenteile wie zum Beispiel Loops oder Rampen.
                <br /><br />Das <b>Erweiterungspaket Carrera Go Plus</b> bietet digitale Konnektivität mit einer Apple- oder Android-<b>App</b>. Mit Carrera Go Plus können die jungen Rennfahrer auch mit einem <b>Geisterauto</b> gegen sich selbst antreten, wenn gerade kein Mitspieler verfügbar ist. Während für die Go Bahn auch Autos mit <b>Front- und Rücklichtern</b> gekauft werden können, enthält die Go Plus Bahn <b>Lautsprecher</b> für authentische Soundeffekte.</p>
            </div>
            <div className='' >
              <img src={go} className="margin" alt="Carrera Go und Go Plus" style={{width: 240, minWidth: 240, marginLeft: 15, objectFit: "contain"}}/>
            </div>
          </div>
          <Video title="Carrera GO!!! Video" description={"Wenn sie einen Eindruck von der Bahn bekommen wollen oder hilfe bei dem Aufbau brauchen, hilft ihnen vielleicht dieses Video."} srcUrl={"https://www.youtube.com/embed/P8DyFK3uFLU"} duration="4 Minuten 10 Sekunden" channelLink="https://www.youtube.com/c/Carrera_official/videos" channelName="Carrera"  />
          
          <h2 id="digital" className="border">Digitale Bahnen</h2>
          <p>Es gibt 3 verschiedene digitale Bahnen, von denen jedes einen bestimmten Grad an Interoperabilität mit den Analogen Bahnen aufweist. Die Bahnen Go (und Go Plus) sind mit Carrera Digital 143 kompatibel aber die Digital 143 wurde 2021 aus dem Sortiment genommen und es werden nur noch Restbestände verkauft. Mit Digital 143 sind drei Fahrzeuge auf der Strecke zugelassen. Carrera Evolution ist kompatibel mit Digital 132.</p>
          <div className='row'>
            <div className=''>
              <h3 id="digital124">Carrera Digital 124</h3>
              <p>Die Größte Carrera Bahn ist mit einem Maßstab von <b>1:24</b> (Autos ungefähr 19cm lang) die Digital 124. Die Bahn ist deutlicher <b>hochwertiger verabeitet</b> als die Digital 143. Es können bis zu <b>4 Personen gleichzeitig</b> auf einer Spur fahren. Auf einer Digital 124 Bahn können auch die kleineren <b>1:32 Autos</b> fahren aber wenn sie die Größeren <b>1:24 Autos</b> benutzen wollen, <b>müssen</b> sie <b>Randstreifen hinzukaufen</b>. Als Zubehör ist auch eine Pit Stop lane erhältlich. 
                Für die Bahn wird ein Mindestalter von 10 Jahren empfohlen. Es kann, wie andere Sets auch, erweitert werden.</p>
            </div>
            <div className='' >
              <img src={d124} className="margin" alt="Carrera Digital 124" style={{width: 240, minWidth: 240, marginLeft: 15, objectFit: "contain"}}/>
            </div>
          </div>
          <Video title="Carrera Digital 124" description={"In diesem Video von können sie eine Digital 124 Bahn in Action sehen."} srcUrl={"https://www.youtube.com/embed/APSUkBapqNk"} duration="1 Minuten 58 Sekunden" channelLink="https://www.youtube.com/channel/UCja23RkNPDU6Suuf9vWVsGA/videos" channelName="Slotracing Voitsberg" />

          <div className='row'>
            <div className=''>
              <h3 id="digital132">Carrera Digital 132</h3>
              <p>Die Unterschiede zwischen den 132 und 124 Digital Bahnen ist ziemlich gering. Die Schienen sind genauso groß wie die der 124 Bahn aber es können nicht 1:24 Autos gefahren werden. Einer der größeren Unterschiede ist das auf der 132 Bahn 6 Fahrzeuge gleichzeitig fahren können.</p>
            </div>
          </div>
          
        </div>
        <div className='' style={{width: 240, minWidth: 240, marginLeft: 25}}>
          <Inhaltsverzeichnis content={[
            {
              id: "digitalanalog",
              text: "Digital oder Analog",
              type: "subtitle"
            },
            {
              id: "analog",
              text: "Analoge Bahnen",
              type: "subtitle"
            }, 
            {
              id: "first",
              text: "Carrera First"
            }, 
            {
              id: "go",
              text: "Carrera Go und Go Plus"
            }, 
            {
              id: "evolution",
              text: "Carrera Evolution"
            }, 
            {
              id: "digital",
              text: "Digitale Bahnen",
              type: "subtitle"
            },
            {
              id: "digital124",
              text: "Carrera Digital 124"
            }, 
            {
              id: "digital132",
              text: 'Carrera Digital 132'
            }
          ]
          }/>
        </div>
      </div>
    </div>
  </Layout>
)

export default Ratgeber
